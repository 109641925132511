<template>
  <v-app>
    <v-app-bar
        dense
      app
        dark
        color="#600902"
    >
      <v-app-bar-nav-icon @click="drawer = true" />

      <v-toolbar-title>Pagina di amministrazione</v-toolbar-title>
      <v-navigation-drawer
        v-model="drawer"  
        absolute
        temporary
        app
      >
        <template v-slot:prepend>
          <v-list color="white">
            <v-list-item>
              <v-img src="../../public/logo.jpg" />
            </v-list-item>
          </v-list>
        </template>

        <v-divider />

        <v-list
          nav
          dense
        >
          <v-list-item
            link
            @click="create"
          >
            <v-list-item-icon>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>AGGIUNGI</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="modify"
          >
            <v-list-item-icon>
              <v-icon>mdi-movie-edit-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>MODIFICA</v-list-item-title>
          </v-list-item>
          <!--        <v-list-item link @click="settings">-->
          <!--          <v-list-item-icon>-->
          <!--            <v-icon>mdi-settings-outline</v-icon>-->
          <!--          </v-list-item-icon>-->
          <!--          <v-list-item-title>SETTINGS</v-list-item-title>-->
          <!--        </v-list-item>-->
          <!--        <div style="height: 100%"></div>-->
          <v-spacer style="height: 100%" />
          <v-divider style="height: 100%" />
          <v-list-item
            link
            @click="logOut"
          >
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>LOGOUT</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-app-bar>


    <!-- Sizes your content based upon application components -->
    <!-- Provides the application the proper gutter -->
    <v-container class="fill-height " style="margin-bottom: 110px">
      <!-- If using vue-router -->
      <router-view />
    </v-container>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
  import db from "../firebaseConfiguration/firebaseInit";

export default {
  name: "Admin",
  data(){
    return{
      drawer:false,
    }
  },
  methods:{
    logOut () {

      db.auth().signOut()
              .then(() => {
                document.cookie = "auth= ;-1;path=/";
                this.$router.replace('/login')
              });

    },
    modify(){
      this.$router.push('modify')
    },
    create(){
      this.$router.push('create')
    },
    settings(){
      this.$router.push('settings')
    }
  }
};
</script>

<style>
</style>
